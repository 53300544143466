import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import assert from 'assert';
import { useHistory } from 'react-router-dom';
import useCcloStyles from '../../../Themes/UseBeecomingStyles';
import UserData, { getUserCopy } from '../../../Datas/UserData';
import EditPasswordDialog from '../BeecomingCompenents/EditPasswordDialog';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import { CompleteAddress } from '../../../Types/ProjectTypes/Address';
import { BasicUser, UserEditPath } from '../../../Types/BeecomingTypes/User';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';

interface Props {}

const AdminProfil: AdminPageComponent = ({ onError }) => {
  const history = useHistory();
  let user: BasicUser = UserData.getBasicUser();

  // user Property
  const [editUser, setEditUser] = useState<BasicUser>(user);

  // fields errors
  // -> textFields are red when error = true
  const [firstNameError, setFirstnameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  // const [addressError, setAddressError] = useState<boolean>(false);

  // fields disable
  // FOR FUTUR: to use Admin Address
  // const [isStreetDisable, setIsStreetDisable] = useState<boolean>(true);
  // const [isStreetNumberDisable, setIsStreetNumberDisable] = useState<boolean>(true);

  // Dialogs
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState<boolean>(false);

  // styles
  const ccloClasses = useCcloStyles();

  // address

  // #region Edit user Properties function
  const setEditUserProperty = (
    k: 'firstName' | 'lastName' | 'email' | 'phoneNumber',
    value: string,
  ): void => {
    handleErrorFromKey(k, false);
    const cpyUser: BasicUser = getUserCopy(editUser);
    cpyUser[k] = value;
    setEditUser(cpyUser);
  };

  // #region Set user address
  // Admin doesn't have address for now
  // const isValidCity = (city: string): boolean => ['ORTHEZ', 'MOURENX']
  // .includes(city.toUpperCase());

  // const isValidStreet = (_street: string): boolean => true;

  // FOR FUTUR: to use Admin Address
  // eslint-disable-next-line no-unused-vars
  const setEditUserCity = (city: string) => {
    handleErrorFromKey('address', false);
    const cpyUser: BasicUser = getUserCopy(editUser);
    if (cpyUser.address === null) {
      cpyUser.address = {
        commune: city,
        streetName: null,
        completeAdress: null,
      };
    } else {
      cpyUser.address.commune = city;
    }
    // setIsStreetDisable(!isValidCity(city));
    // setIsStreetNumberDisable(!isValidStreet(editUser.address?.streetName ?? ''));
    setEditUser(cpyUser);
  };

  // FOR FUTUR: to use Admin Address
  // eslint-disable-next-line no-unused-vars
  const setEditUserStreetName = (streetName: string) => {
    handleErrorFromKey('address', false);
    const cpyUser: BasicUser = getUserCopy(editUser);
    // assert(cpyUser.address !== null);
    if (cpyUser.address !== null) cpyUser.address.streetName = streetName;
    // setIsStreetNumberDisable(!isValidStreet(streetName));
    setEditUser(cpyUser);
  };

  const getCompleteAddresseFromNumber = (
    city: string,
    street: string,
    streetNumber: number,
  ): CompleteAddress | null => ({
    numeroSelect: streetNumber,
    GeoPoint2d: [0, 0],
    codeInsee: 0,
    collecteid: [],
  });

  // FOR FUTUR: to use Admin Address
  // eslint-disable-next-line no-unused-vars
  const setEditUserStreetNumber = (streetNumber: number) => {
    handleErrorFromKey('address', false);
    const cpyUser: BasicUser = getUserCopy(editUser);
    // assert(cpyUser.address !== null);
    // assert(cpyUser.address.streetName != null);
    if (cpyUser?.address != null && cpyUser?.address?.completeAdress !== null) {
      cpyUser.address.completeAdress = getCompleteAddresseFromNumber(
        editUser.address?.commune as string,
        editUser.address?.streetName as string,
        streetNumber,
      );
    }

    setEditUser(cpyUser);
  };
  // #endregion

  const handleChangeDefaultKey = (userEditPath: UserEditPath): void => {
    const { key, path } = userEditPath;
    ApiFetch(path, 'PUT', history, editUser[key]).then((resp) => {
      if (resp.ok) {
        // update user instance if request is accepted
        if (key === 'address') {
          UserData.setUserAddres(editUser.address);
        } else {
          UserData.setUserProperty(key, editUser[key]);
        }
        user = UserData.getUser();
      } else {
        handleErrorFromKey(key, true);
      }
    });
  };

  const handleChangeEmail = (): void => {
    ApiFetch('/compte/email/changement', 'PUT', history, {
      email: editUser.email,
    }).then((resp) => {
      if (resp.ok) {
        setIsEmailDialogOpen(true);
      } else {
        handleErrorFromKey('email', true);
      }
    });
  };

  const handleChangeKey = (userEditPath: UserEditPath) => {
    if (userEditPath.key === 'email') {
      handleChangeEmail();
    } else {
      handleChangeDefaultKey(userEditPath);
    }
  };

  const handleErrorFromKey = (key: keyof BasicUser, value: boolean): void => {
    switch (key) {
      case 'firstName':
        setFirstnameError(value);
        break;
      case 'lastName':
        setLastNameError(value);
        break;
      case 'email':
        setEmailError(value);
        break;
      case 'phoneNumber':
        setPhoneNumberError(value);
        break;
      case 'address':
        // setAddressError(value);
        break;
      default:
        break;
    }
    onError("L'un des champs n'a pas été modifié.");
  };

  const clearError = () => {
    setFirstnameError(false);
    setLastNameError(false);
    setEmailError(false);
    setPhoneNumberError(false);
    // setAddressError(false);
  };

  // check which property have been edited
  // and start editing procedure for each of them
  const handleValidate = () => {
    const editedProperties = UserData.getEditedProperties(editUser);
    editedProperties.forEach((property) => {
      handleChangeKey(property);
    });
  };

  const handleCancel = () => {
    clearError();
    setEditUser(user);
  };
  // #endregion

  // #region Dialogs functions
  const handleCloseEmailDialod = () => {
    setIsEmailDialogOpen(false);
  };

  const handleOpenChangePassword = () => {
    setIsPasswordDialogOpen(true);
  };
  // #endregion
  return (
    <div>
      <h1>Mes informations</h1>
      <div className="newElementContainer">
        <div className="newElementColumn">
          <div className="newElementProperty">
            <TextField
              value={editUser.firstName}
              label="Prénom"
              error={firstNameError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEditUserProperty('firstName', event.target.value);
              }}
            />
          </div>
          <div className="newElementProperty">
            <TextField
              value={editUser.lastName}
              label="Nom de famille"
              error={lastNameError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEditUserProperty('lastName', event.target.value);
              }}
            />
          </div>
          <div className="newElementProperty">
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => {
                handleCancel();
              }}
            >
              Annuler les modifications
            </Button>
          </div>
        </div>
        <div className="newElementColumn">
          <div className="newElementProperty">
            <TextField
              value={editUser.email}
              label="email"
              type="email"
              error={emailError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEditUserProperty('email', event.target.value);
              }}
            />
            <Dialog open={isEmailDialogOpen} onClose={handleCloseEmailDialod}>
              <DialogContent>
                <DialogContentText>
                  Vous avez modifié votre email. Un email de confirmation va être envoyé à votre
                  nouvelle adresse email.
                </DialogContentText>
                <DialogActions>
                  <Button className={ccloClasses.BeecomingButton} onClick={handleCloseEmailDialod}>
                    Fermer
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </div>
          <div className="newElementProperty">
            <TextField
              value={editUser.phoneNumber}
              label="numéro de téléphone"
              error={phoneNumberError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEditUserProperty('phoneNumber', event.target.value);
              }}
            />
          </div>
          <div className="newElementProperty">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                handleValidate();
              }}
            >
              Valider
            </Button>
          </div>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleOpenChangePassword();
        }}
      >
        Modifier mon mot de passe
      </Button>
      <EditPasswordDialog open={isPasswordDialogOpen} setOpen={setIsPasswordDialogOpen} />
    </div>
  );
};

const AdminProfilProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Mon Compte"
    screenName="account"
    menuPath="account"
    adminPage={AdminProfil}
  />
);

export default AdminProfilProtected;
