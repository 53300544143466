/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import React, {
  ChangeEvent,
  FunctionComponent,
  KeyboardEventHandler,
  ReactChild,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AddBox, DeleteForever, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';

// import icone pour tuiles
import allIcons from '../../Global/IconesSignalements';

interface Props {}

type AllIcons = {
  [key: string]: any;
};

type KeyOfCategory =
  | 'id'
  | 'name'
  | 'serviceId'
  | 'icon'
  | 'isActive'
  | 'parentCategory'
  | 'subCategories';

type Category = {
  // eslint-disable-next-line no-unused-vars
  [K in KeyOfCategory]?: string | number | boolean | null | Category[];
};

const AddCategory: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [actualize, setActualize] = useState<boolean>(false);
  const [snackbarIsActive, setSnackBarIsActive] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [allCategories, setAllCategories] = useState<Category[]>();

  const history = useHistory();

  const getCategories = async () => {
    try {
      const response = await ApiFetch('/report-category', 'GET', history);
      if (response.ok) {
        const categories = (await response.json()) as Category[];
        setAllCategories(categories);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCategories()
      .then(() => setIsLoading(false))
      .catch((e) => {
        console.error(e);
        setSuccess(false);
        setSnackBarIsActive(true);
      });
  }, [actualize]);

  const handleDeleteCategory = (id: string) => {
    ApiFetch(`/report-category/${id}`, 'DELETE', history)
      .then((result) => {
        if (result.ok) {
          setActualize((state) => !state);
          setSuccess(true);
          setSnackBarIsActive(true);
        }
      })
      .catch((e) => {
        console.error(e);
        setSuccess(false);
        setSnackBarIsActive(true);
      });
  };

  const CurrentIcon = ({ label }: { label: string }) => {
    const current = (allIcons as AllIcons)[label];
    return current ? current() : <div />;
  };

  const CustomCell = ({
    maxWidth,
    header,
    children,
  }: {
    maxWidth?: number;
    header?: boolean;
    children?: ReactChild | ReactChild[] | string | number | null | Category[] | undefined | false;
  }) => (
    <TableCell
      style={{ maxWidth: maxWidth || undefined, fontWeight: header ? 'bold' : 'normal' }}
      align="center"
    >
      {children}
    </TableCell>
  );

  const EditableCell = ({
    value,
    inputLabel,
    inputName,
    type,
    rowId,
    subcat,
  }: {
    value: string | number | null | Category[] | boolean;
    inputLabel: string;
    inputName: string;
    type: 'input' | 'checkbox' | 'select';
    rowId: string;
    subcat?: boolean;
  }) => {
    const [newValue, setNewValue] = useState(value);
    const [isEditable, setIsEditable] = useState<boolean>(false);

    useEffect(() => {
      updateCellOnDb();
    }, [isEditable]);

    const handleChangeValue = (event: any) => {
      switch (type) {
        case 'select':
          setNewValue(event?.target?.value);
          setIsEditable((state) => !state);
          break;
        case 'checkbox':
          setNewValue((state) => !state);
          setIsEditable((state) => !state);
          break;
        default:
          setNewValue(event?.target?.value);
      }
    };

    const handleDoubleClick = () => {
      setIsEditable((state) => !state);
    };

    const handleKeyDown: KeyboardEventHandler = (event) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        updateCellOnDb();
        handleDoubleClick();
      }
    };

    const updateCellOnDb = () => {
      if (value !== newValue) {
        let catUpdate: Category | undefined;
        if (subcat) {
          allCategories?.forEach((e) => {
            const checkSubCat = (e.subCategories &&
              (e.subCategories as Category[]).find((elem) => elem.id === rowId)) as
              | Category
              | undefined;

            if (checkSubCat) {
              catUpdate = checkSubCat;
            }
          });
        } else {
          catUpdate = allCategories?.find((e) => e.id === rowId);
        }
        if (catUpdate) {
          catUpdate[inputName as KeyOfCategory] = newValue;
          ApiFetch(`/report-category/${rowId}`, 'PUT', history, catUpdate)
            .then((result) => {
              if (result.ok) {
                setActualize((state) => !state);
                setSuccess(true);
                setSnackBarIsActive(true);
              }
            })
            .catch((error) => {
              console.error(error);
              setSuccess(false);
              setSnackBarIsActive(true);
            });
        }
      }
    };
    type TypeArray = string | Category;

    return !isEditable && type !== 'checkbox' ? (
      <p onDoubleClick={handleDoubleClick}>
        {inputName === 'icon' ? <CurrentIcon label={newValue as string} /> : newValue}
      </p>
    ) : (
      <FormControl>
        {type === 'select' && (
          <FormControl>
            <Select
              id="inputForUpdate"
              value={newValue}
              onChange={handleChangeValue}
              onDoubleClick={handleDoubleClick}
              label={inputLabel}
              onKeyDown={handleKeyDown}
            >
              <MenuItem value={undefined}>Aucune</MenuItem>
              {((inputName === 'icon' ? Object.keys(allIcons) : allCategories) as TypeArray[])?.map(
                (cat) => (
                  <MenuItem value={typeof cat === 'string' ? cat : (cat.id as string)}>
                    {typeof cat === 'string' ? <CurrentIcon label={cat} /> : (cat.name as string)}
                  </MenuItem>
                ),
              )}
            </Select>
            <FormHelperText>{inputLabel}</FormHelperText>
          </FormControl>
        )}
        {type === 'input' && (
          <>
            <InputLabel htmlFor="inputForUpdate">{inputLabel}</InputLabel>
            <Input
              id="inputForUpdate"
              name={inputName}
              onChange={handleChangeValue}
              value={newValue}
              onDoubleClick={handleDoubleClick}
              onKeyDown={handleKeyDown}
            />
          </>
        )}
        {type === 'checkbox' && (
          <FormControlLabel
            control={<Checkbox checked={newValue as boolean} onChange={handleChangeValue} />}
            label={newValue ? 'Visible' : 'Invisible'}
          />
        )}
      </FormControl>
    );
  };

  const RowCategory = ({ value }: { value: Category }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
      <>
        <TableRow>
          <CustomCell maxWidth={10}>
            {value.subCategories && (value.subCategories as Category[]).length !== 0 && (
              <Button onClick={() => setIsOpen((state) => !state)}>
                {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </Button>
            )}
          </CustomCell>
          <CustomCell>
            <EditableCell
              value={value.name as string}
              inputLabel="Modifier le nom"
              inputName="name"
              type="input"
              rowId={value.id as string}
            />
          </CustomCell>
          <CustomCell maxWidth={20}>
            <EditableCell
              value={value.icon as string}
              inputLabel="Modifier l'icone"
              inputName="icon"
              type="select"
              rowId={value.id as string}
            />
          </CustomCell>
          <CustomCell maxWidth={50}>
            <EditableCell
              value={value.serviceId as number}
              inputLabel="Modifier l'identifiant du service"
              inputName="serviceId"
              type="input"
              rowId={value.id as string}
            />
          </CustomCell>
          <CustomCell maxWidth={150} />
          <CustomCell maxWidth={50}>
            <EditableCell
              value={value.isActive as boolean}
              inputLabel="Active"
              inputName="isActive"
              type="checkbox"
              rowId={value.id as string}
            />
          </CustomCell>
          <CustomCell maxWidth={20}>
            <Button onClick={() => value.id && handleDeleteCategory(value.id as string)}>
              <DeleteForever color="error" />
            </Button>
          </CustomCell>
        </TableRow>
        {isOpen &&
          value.subCategories &&
          (value.subCategories as Category[]).length !== 0 &&
          (value.subCategories as Category[]).map((subcat) => (
            <TableRow hover key={subcat.id as string}>
              <CustomCell maxWidth={10} />
              <CustomCell maxWidth={150}>
                <EditableCell
                  value={subcat.name as string}
                  inputLabel="Modifier le nom"
                  inputName="name"
                  type="input"
                  rowId={subcat.id as string}
                  subcat
                />
              </CustomCell>
              <CustomCell maxWidth={20}>
                <EditableCell
                  value={subcat.icon as string}
                  inputLabel="Modifier l'icone"
                  inputName="icon"
                  type="select"
                  rowId={subcat.id as string}
                />
              </CustomCell>
              <CustomCell maxWidth={50}>
                <EditableCell
                  value={subcat.serviceId as number}
                  inputLabel="Modifier le service"
                  inputName="serviceId"
                  type="input"
                  rowId={subcat.id as string}
                  subcat
                />
              </CustomCell>
              <CustomCell maxWidth={150}>
                <EditableCell
                  value={value.name as string}
                  inputLabel="Modifier la catégorie parent"
                  inputName="parentCategory"
                  type="select"
                  rowId={subcat.id as string}
                  subcat
                />
              </CustomCell>
              <CustomCell maxWidth={50}>
                <EditableCell
                  value={subcat.isActive as boolean}
                  inputLabel="Active"
                  inputName="isActive"
                  type="checkbox"
                  rowId={subcat.id as string}
                  subcat
                />
              </CustomCell>
              <CustomCell maxWidth={20}>
                <Button onClick={() => subcat.id && handleDeleteCategory(subcat.id as string)}>
                  <DeleteForever color="error" />
                </Button>
              </CustomCell>
            </TableRow>
          ))}
      </>
    );
  };

  const NewFormCat = () => {
    const [nameCategory, setNameCategory] = useState<string>('');
    const [serviceCategory, setServiceCategory] = useState<number>();
    const [parentcatCategory, setParentcatCategory] = useState<string>('');
    const [iconCategory, setIconCategory] = useState<string>('');
    const [isActiveCategory, setIsActiveCategory] = useState<boolean>(false);
    const [isError, setIsError] = useState<Record<string, boolean>>({
      name: false,
      service: false,
      icon: false,
    });

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
      setNameCategory(event.target.value);
      setIsError((state) => ({
        ...state,
        name: false,
      }));
    };

    const handleChangeService = (event: ChangeEvent<HTMLInputElement>) => {
      setServiceCategory(parseInt(event.target.value, 10));
      setIsError((state) => ({
        ...state,
        service: false,
      }));
    };

    const handleChangeIsActive = () => {
      setIsActiveCategory((state) => !state);
    };

    const handleChangeParentCategory = (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    ) => {
      setParentcatCategory(event.target.value as string);
    };

    const handleChangeIcon = (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    ) => {
      setIconCategory(event.target.value as string);
      setIsError((state) => ({
        ...state,
        icon: false,
      }));
    };

    const handleAddCategory = (e: any) => {
      e.preventDefault();

      const checkDatas = {
        name: nameCategory,
        service: serviceCategory,
        icon: iconCategory,
      };

      Object.entries(checkDatas).forEach(([key, value]) => {
        if (!value) {
          setIsError((state) => ({
            ...state,
            [key]: true,
          }));
        }
      });

      if (nameCategory && serviceCategory && iconCategory) {
        const newCategory: Category = {
          name: nameCategory,
          icon: iconCategory,
          serviceId: serviceCategory,
          isActive: isActiveCategory,
          parentCategory: parentcatCategory?.length !== 0 ? parentcatCategory : null,
        };

        setIsLoading(true);

        ApiFetch('/report-category', 'POST', null, newCategory)
          .then(async (result) => {
            if (result.ok) {
              const validate = await result.json();
              if (validate) {
                setSuccess(true);
                setSnackBarIsActive(true);
                setActualize((state) => !state);
                setIsLoading(false);
                setNameCategory('');
                setServiceCategory(undefined);
                setParentcatCategory('');
                setIconCategory('');
                setIsActiveCategory(false);
              }
            }
          })
          .catch((error) => {
            console.error(error);
            setSuccess(false);
          });
      }
    };

    return (
      <TableRow>
        <CustomCell maxWidth={20} />
        <CustomCell maxWidth={100}>
          <TextField
            label="Nom de la catégorie"
            name="name"
            error={isError.name}
            required
            value={nameCategory}
            onChange={handleChangeName}
            variant="outlined"
            style={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </CustomCell>
        <CustomCell maxWidth={100}>
          <Select
            id="icon"
            error={isError.icon}
            label="icone"
            variant="outlined"
            required
            displayEmpty
            fullWidth
            value={iconCategory}
            onChange={handleChangeIcon}
          >
            <MenuItem value="">Icone</MenuItem>
            {Object.keys(allIcons).map((ico, index) => {
              const CurrentItem = (allIcons as AllIcons)[ico];
              return (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem value={ico} key={index}>
                  <CurrentItem />
                </MenuItem>
              );
            })}
          </Select>
        </CustomCell>
        <CustomCell maxWidth={100}>
          <TextField
            label="Identifiant du service"
            name="serviceId"
            value={serviceCategory}
            onChange={handleChangeService}
            type="number"
            required
            error={isError.service}
            variant="outlined"
            style={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </CustomCell>
        <CustomCell maxWidth={100}>
          <Select
            label="test"
            id="parentCategory"
            variant="outlined"
            required
            displayEmpty
            fullWidth
            name="parentCategory"
            value={parentcatCategory}
            onChange={handleChangeParentCategory}
          >
            <MenuItem value="">Catégorie parent</MenuItem>
            {allCategories?.map((cat) => (
              <MenuItem value={cat.id as string}>{cat.name}</MenuItem>
            ))}
          </Select>
        </CustomCell>
        <CustomCell maxWidth={100}>
          <FormControlLabel
            control={
              <Checkbox
                name="isActive"
                checked={isActiveCategory}
                onChange={handleChangeIsActive}
              />
            }
            label={isActiveCategory ? 'Visible' : 'Invisible'}
          />
        </CustomCell>
        <CustomCell maxWidth={20}>
          <Button color="secondary" onClick={handleAddCategory}>
            <AddBox color="primary" fontSize="large" />
          </Button>
        </CustomCell>
      </TableRow>
    );
  };

  const ListOfCategories = () => (
    <>
      <Table>
        <TableHead>
          <TableRow style={{ border: 'solid 2px lightgray' }}>
            <CustomCell maxWidth={20} />
            <CustomCell maxWidth={100} header>
              Nom
            </CustomCell>
            <CustomCell maxWidth={100} header>
              Icone
            </CustomCell>
            <CustomCell maxWidth={100} header>
              Référence du service
            </CustomCell>
            <CustomCell maxWidth={100} header>
              Catégorie parent
            </CustomCell>
            <CustomCell maxWidth={100} header>
              Afficher sur l&apos; application
            </CustomCell>
            <CustomCell maxWidth={20} />
          </TableRow>
        </TableHead>
        <TableBody>
          {allCategories?.map((cat, index) => (
            <RowCategory value={cat} key={`category-${index}`} />
          ))}
        </TableBody>
        <NewFormCat />
      </Table>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarIsActive}
        autoHideDuration={2000}
        onClose={() => setSnackBarIsActive(false)}
        message={success ? 'Mise à jour réussie' : 'Mise à jour impossible...'}
      />
    </>
  );

  return isLoading ? <CircularProgress style={{ position: 'absolute' }} /> : <ListOfCategories />;
};

const ListSignalement: AdminPageComponent = ({ onError }) => <AddCategory />;

const ListSignalementProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Signalements"
    menuPath="signalement"
    screenName="signalement"
    adminPage={ListSignalement}
  />
);

export default ListSignalementProtected;
