/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import React, {
  ChangeEvent,
  FunctionComponent,
  KeyboardEventHandler,
  ReactChild,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DeleteForever, DragIndicator } from '@material-ui/icons';
import { Autocomplete } from '@mui/material';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';

// import icone pour tuiles
import accommodation_certificate from '../../../assets/icons/icons-procedures/accommodation_certificate.svg';
import electoral_list from '../../../assets/icons/icons-procedures/electoral_list.svg';
import electrical_bike from '../../../assets/icons/icons-procedures/electrical_bike.svg';
import grey_card from '../../../assets/icons/icons-procedures/grey_card.svg';
import id_card from '../../../assets/icons/icons-procedures/id_card.svg';
import online_ask from '../../../assets/icons/icons-procedures/online_ask.svg';
import passport from '../../../assets/icons/icons-procedures/passport.svg';
import retirement from '../../../assets/icons/icons-procedures/retirement.svg';
import unique_spot from '../../../assets/icons/icons-procedures/unique_spot.svg';

interface Props {}

type AllIcons = {
  [key: string]: any;
};

type KeyOfProcedure = 'id' | 'name' | 'order' | 'url' | 'icon' | 'isActive' | 'theme';

type Procedure = {
  // eslint-disable-next-line no-unused-vars
  [K in KeyOfProcedure]?: string | number | boolean;
};

const allIcons = {
  accommodation_certificate: () => (
    <img
      src={accommodation_certificate}
      alt="icone-accomodation_certificate"
      width={30}
      height={30}
    />
  ),
  electoral_list: () => (
    <img src={electoral_list} alt="icone-electoral_list" width={30} height={30} />
  ),
  electrical_bike: () => (
    <img src={electrical_bike} alt="icone-electrical_bike" width={30} height={30} />
  ),
  grey_card: () => <img src={grey_card} alt="icone-grey_card" width={30} height={30} />,
  id_card: () => <img src={id_card} alt="icone-id_card" width={30} height={30} />,
  online_ask: () => <img src={online_ask} alt="icone-online_ask" width={30} height={30} />,
  passport: () => <img src={passport} alt="icone-passport" width={30} height={30} />,
  retirement: () => <img src={retirement} alt="icone-retirement" width={30} height={30} />,
  unique_spot: () => <img src={unique_spot} alt="icone-unique_spot" width={30} height={30} />,
};

const AddProcedure: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [actualize, setActualize] = useState<boolean>(false);
  const [snackbarIsActive, setSnackBarIsActive] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [allProcedures, setAllProcedures] = useState<Procedure[]>([]);
  const [filterListTheme, setFilterListTheme] = useState<string[]>([]);

  const history = useHistory();

  const getProcedures = async () => {
    try {
      const response = await ApiFetch('/procedures', 'GET', history);
      if (response.ok) {
        const procedures = (await response.json()) as Procedure[];
        setAllProcedures(procedures.sort((a, b) => (a.order as number) - (b.order as number)));
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getProcedures()
      .then(() => setIsLoading(false))
      .catch((e) => {
        console.error(e);
        setSuccess(false);
        setSnackBarIsActive(true);
      });
  }, [actualize]);

  useEffect(() => {
    const themes: string[] = [];
    allProcedures?.forEach((proc) => {
      if (!themes.includes(proc.theme as string)) {
        themes.push(proc.theme as string);
      }
    });
    setFilterListTheme(themes);
  }, [allProcedures]);

  const handleDeleteProcedure = (id: string) => {
    ApiFetch(`/procedures/${id}`, 'DELETE', history)
      .then((result) => {
        if (result.ok) {
          setActualize((state) => !state);
          setSuccess(true);
          setSnackBarIsActive(true);
        }
      })
      .catch((e) => {
        console.error(e);
        setSuccess(false);
        setSnackBarIsActive(true);
      });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newPosition = result.destination.index;
    const rowId = result.draggableId;
    const rowUpdate = allProcedures.find((e) => e.id === rowId);

    if (rowUpdate) {
      (rowUpdate.order as number) = newPosition;

      ApiFetch(`/procedures/${rowId as string}`, 'PUT', history, rowUpdate)
        .then(() => {
          setActualize((state) => !state);
          setSuccess(true);
          setSnackBarIsActive(true);
        })
        .catch((error) => {
          console.error(error);
          setSuccess(false);
          setSnackBarIsActive(true);
        });
    }
  };

  const CurrentIcon = ({ label }: { label: string }) => {
    const current = (allIcons as AllIcons)[label];
    return current ? current() : <div />;
  };

  const CustomCell = ({
    maxWidth,
    header,
    children,
  }: {
    maxWidth?: number;
    header?: boolean;
    children?:
      | ReactChild
      | ReactChild[]
      | string
      | number
      | null
      | Procedure[]
      | undefined
      | boolean;
  }) => (
    <TableCell
      style={{ maxWidth: maxWidth || undefined, fontWeight: header ? 'bold' : 'normal' }}
      align="center"
    >
      {children}
    </TableCell>
  );

  const EditableCell = ({
    value,
    inputLabel,
    inputName,
    type,
    rowId,
  }: {
    value: string | number | boolean;
    inputLabel: string;
    inputName: string;
    type: 'input' | 'checkbox' | 'select' | 'autocomplete';
    rowId: string;
  }) => {
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [newValue, setNewValue] = useState(value);

    useEffect(() => {
      updateCellOnDb();
    }, [isEditable]);

    const handleDoubleClick = () => {
      setIsEditable((state) => !state);
    };

    const handleChangeValue = (event: any) => {
      switch (type) {
        case 'checkbox':
          setNewValue((state) => !state);
          setIsEditable((state) => !state);
          break;
        case 'select':
          setNewValue(event?.target?.value);
          setIsEditable((state) => !state);
          break;
        case 'autocomplete':
          setNewValue(
            event?.target?.innerText?.length !== 0
              ? event?.target?.innerText
              : event?.target?.value,
          );
          break;
        default:
          setNewValue(event?.target?.value);
      }
    };

    const handleKeyDown: KeyboardEventHandler = (event) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        updateCellOnDb();
        handleDoubleClick();
      }
    };

    const updateCellOnDb = () => {
      if (value !== newValue) {
        const catUpdate = allProcedures?.find((e) => e.id === rowId);

        if (catUpdate) {
          catUpdate[inputName as KeyOfProcedure] = newValue;
          ApiFetch(`/procedures/${rowId}`, 'PUT', history, catUpdate)
            .then((result) => {
              if (result.ok) {
                setActualize((state) => !state);
                setSuccess(true);
                setSnackBarIsActive(true);
              }
            })
            .catch((error) => {
              console.error(error);
              setSuccess(false);
              setSnackBarIsActive(true);
            });
        }
      }
    };
    type TypeArray = string | Procedure;
    return !isEditable && type !== 'checkbox' ? (
      <p onDoubleClick={handleDoubleClick}>
        {inputName === 'icon' ? <CurrentIcon label={newValue as string} /> : newValue}
      </p>
    ) : (
      <FormControl style={{ minWidth: 150 }}>
        {type === 'input' && (
          <>
            <InputLabel htmlFor="inputForUpdate">{inputLabel}</InputLabel>
            <Input
              id="inputForUpdate"
              name={inputName}
              onChange={handleChangeValue}
              value={newValue}
              onDoubleClick={handleDoubleClick}
              onKeyDown={handleKeyDown}
            />
          </>
        )}
        {type === 'select' && (
          <FormControl>
            <Select
              id="inputForUpdate"
              value={newValue}
              onChange={handleChangeValue}
              onDoubleClick={handleDoubleClick}
              label={inputLabel}
              onKeyDown={handleKeyDown}
            >
              <MenuItem value={undefined}>Aucune</MenuItem>
              {((inputName === 'icon' ? Object.keys(allIcons) : allProcedures) as TypeArray[])?.map(
                (cat) => (
                  <MenuItem value={typeof cat === 'string' ? cat : (cat.id as string)}>
                    {typeof cat === 'string' ? <CurrentIcon label={cat} /> : (cat.name as string)}
                  </MenuItem>
                ),
              )}
            </Select>
            <FormHelperText>{inputLabel}</FormHelperText>
          </FormControl>
        )}
        {type === 'checkbox' && (
          <FormControlLabel
            control={<Checkbox checked={newValue as boolean} onChange={handleChangeValue} />}
            label={newValue ? 'Visible' : 'Invisible'}
          />
        )}
        {type === 'autocomplete' && (
          <Autocomplete
            disablePortal
            options={filterListTheme}
            fullWidth
            onKeyDown={handleKeyDown}
            value={newValue as string}
            onChange={handleChangeValue}
            onInputChange={handleChangeValue}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                name="theme"
                style={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
                label={inputLabel}
              />
            )}
          />
        )}
      </FormControl>
    );
  };

  const RowCategory = ({ value }: { value: Procedure }) => {
    const getItemStyle = (
      isDragging: boolean,
      draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    ) => ({
      // styles we need to apply on draggables
      ...draggableStyle,

      ...(isDragging && {
        background: 'rgb(21, 129, 136)',
        position: 'inherit',
      }),
    });

    const DraggableComponent = (id: string, index: number) => (props: any) => {
      const { children } = props;
      return (
        <Draggable draggableId={id} index={index}>
          {(provided, snapshot) => (
            <TableRow
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              {...props}
            >
              {children}
            </TableRow>
          )}
        </Draggable>
      );
    };

    return (
      <TableRow
        component={DraggableComponent(value.id as string, value.order as number)}
        key={value.id as string}
      >
        <CustomCell maxWidth={20} header>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <DragIndicator color="primary" style={{ width: 40, height: 40 }} />
            {(value.order as number) < 10 ? `0${value.order}` : value.order}
          </div>
        </CustomCell>
        <CustomCell maxWidth={20}>
          <EditableCell
            value={value.icon as string}
            inputLabel="Modifier l'icone"
            inputName="icon"
            type="select"
            rowId={value.id as string}
          />
        </CustomCell>
        <CustomCell maxWidth={150}>
          <EditableCell
            value={value.name as string}
            inputLabel="Modifier le nom"
            inputName="name"
            type="input"
            rowId={value.id as string}
          />
        </CustomCell>

        <CustomCell maxWidth={150}>
          <EditableCell
            value={value.url as string}
            inputLabel="Modifier l'url"
            inputName="url"
            type="input"
            rowId={value.id as string}
          />
        </CustomCell>
        <CustomCell maxWidth={150}>
          <EditableCell
            value={value.theme as string}
            inputLabel="Modifier le thème"
            inputName="theme"
            type="autocomplete"
            rowId={value.id as string}
          />
        </CustomCell>
        <CustomCell maxWidth={50}>
          <EditableCell
            value={value.isActive as boolean}
            inputLabel="Active"
            inputName="isActive"
            type="checkbox"
            rowId={value.id as string}
          />
        </CustomCell>
        <CustomCell maxWidth={20}>
          <Button onClick={() => value.id && handleDeleteProcedure(value.id as string)}>
            <DeleteForever color="error" />
          </Button>
        </CustomCell>
      </TableRow>
    );
  };

  const NewFormCat = () => {
    const [nameProcedure, setNameProcedure] = useState<string>('');
    const [urlProcedure, setUrlProcedure] = useState<string>('');
    const [iconProcedure, setIconProcedure] = useState<string>('');
    const [isActiveProcedure, setIsActiveProcedure] = useState<boolean>(false);
    const [isError, setIsError] = useState<Record<string, boolean>>({
      name: false,
      url: false,
      icon: false,
      theme: false,
    });

    const [themeProcedure, setThemeProcedure] = useState<string>('');

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
      setNameProcedure(event.target.value);
      setIsError((state) => ({
        ...state,
        name: false,
      }));
    };

    const handleChangeUrl = (event: ChangeEvent<HTMLInputElement>) => {
      setUrlProcedure(event.target.value);
      setIsError((state) => ({
        ...state,
        service: false,
      }));
    };

    const handleChangeTheme = (event: any) => {
      setThemeProcedure(
        event?.target?.innerText?.length !== 0 ? event?.target?.innerText : event?.target?.value,
      );
      setIsError((state) => ({
        ...state,
        theme: false,
      }));
    };

    const handleChangeIcon = (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    ) => {
      setIconProcedure(event.target.value as string);
      setIsError((state) => ({
        ...state,
        icon: false,
      }));
    };

    const handleAddProcedure = (e: any) => {
      e.preventDefault();

      const checkDatas = {
        name: nameProcedure,
        url: urlProcedure,
        icon: iconProcedure,
        theme: themeProcedure,
      };

      Object.entries(checkDatas).forEach(([key, value]) => {
        if (!value) {
          setIsError((state) => ({
            ...state,
            [key]: true,
          }));
        }
      });

      if (nameProcedure && urlProcedure && iconProcedure) {
        const newCategory: Procedure = {
          name: nameProcedure,
          icon: iconProcedure,
          url: urlProcedure,
          isActive: true,
          order: (allProcedures?.length || 0) + 1,
          theme: themeProcedure,
        };

        setIsLoading(true);

        ApiFetch('/procedures', 'POST', null, newCategory)
          .then(async (result) => {
            if (result.ok) {
              const validate = await result.json();
              if (validate) {
                setSuccess(true);
                setSnackBarIsActive(true);
                setActualize((state) => !state);
                setIsLoading(false);
                setNameProcedure('');
                setUrlProcedure('');
                setIconProcedure('');
                setIsActiveProcedure(false);
              }
            }
          })
          .catch((error) => {
            console.error(error);
            setSuccess(false);
          });
      }
    };

    return (
      <TableRow>
        <CustomCell maxWidth={20} />
        <CustomCell maxWidth={100}>
          <Select
            id="icon"
            error={isError.icon}
            label="icone"
            variant="outlined"
            required
            displayEmpty
            fullWidth
            value={iconProcedure}
            onChange={handleChangeIcon}
          >
            <MenuItem value="">Icone</MenuItem>
            {Object.keys(allIcons).map((ico, index) => {
              const CurrentItem = (allIcons as AllIcons)[ico];
              return (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem alignItems="center" value={ico} key={index}>
                  <CurrentItem />
                </MenuItem>
              );
            })}
          </Select>
        </CustomCell>
        <CustomCell maxWidth={100}>
          <TextField
            label="Nom de la démarche"
            name="name"
            error={isError.name}
            required
            value={nameProcedure}
            onChange={handleChangeName}
            variant="outlined"
            style={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </CustomCell>

        <CustomCell maxWidth={100}>
          <TextField
            label="Url de la démarche"
            name="url"
            value={urlProcedure}
            onChange={handleChangeUrl}
            required
            error={isError.url}
            variant="outlined"
            style={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </CustomCell>
        <CustomCell maxWidth={100}>
          <Autocomplete
            disablePortal
            options={filterListTheme}
            fullWidth
            value={themeProcedure}
            onChange={handleChangeTheme}
            onInputChange={handleChangeTheme}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="theme"
                style={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
                error={isError.url}
                label="Thème de la démarche"
              />
            )}
          />
        </CustomCell>
        <CustomCell maxWidth={20}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleAddProcedure}
            style={{ height: 50 }}
          >
            Valider
          </Button>
        </CustomCell>
      </TableRow>
    );
  };

  const ListOfCategories = () => {
    const getListStyle = (isDraggingOver: boolean) => ({
      background: isDraggingOver ? 'lightgray' : 'white',
      height: 'auto',
      boxSizing: 'border-box',
    });

    // eslint-disable-next-line no-shadow
    const DroppableComponent =
      (onDragEnd: (result: any, provided: any) => void) => (props: any) => {
        const { children } = props;
        return (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="1" direction="vertical">
              {(provided, snapshot) => (
                <TableBody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  {...props}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {children}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        );
      };

    return (
      <>
        <Table>
          <TableHead>
            <TableRow style={{ border: 'solid 2px lightgray' }}>
              <CustomCell maxWidth={20} header>
                Ordre
              </CustomCell>
              <CustomCell maxWidth={100} header>
                Icone
              </CustomCell>
              <CustomCell maxWidth={100} header>
                Démarche
              </CustomCell>
              <CustomCell maxWidth={100} header>
                Url
              </CustomCell>
              <CustomCell maxWidth={100} header>
                Thème
              </CustomCell>
              <CustomCell maxWidth={100} header>
                Afficher sur l&apos; application
              </CustomCell>
              <CustomCell maxWidth={20} />
            </TableRow>
          </TableHead>
          <TableBody component={DroppableComponent(onDragEnd)}>
            {allProcedures?.map((cat, index) => (
              <RowCategory value={cat} key={`procedure-${index}`} />
            ))}
          </TableBody>
          <NewFormCat />
        </Table>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarIsActive}
          autoHideDuration={2000}
          onClose={() => setSnackBarIsActive(false)}
          message={success ? 'Mise à jour réussie' : 'Mise à jour impossible...'}
        />
      </>
    );
  };

  return isLoading ? <CircularProgress style={{ position: 'absolute' }} /> : <ListOfCategories />;
};

const ListProcedure: AdminPageComponent = ({ onError }) => <AddProcedure />;

const ListProcedureProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Démarches en ligne"
    menuPath="demarche"
    screenName="demarche"
    adminPage={ListProcedure}
  />
);

export default ListProcedureProtected;
